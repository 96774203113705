import Box from "@mui/material/Box";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Container from "@mui/material/Container";
import { useTheme } from "@mui/material/styles";

import { ElevationScroll } from "./elevation-scroll";

type ViewBoxAppBarProps = {
  readonly primaryAction?: React.ReactNode;
  readonly title?: React.ReactNode;
  readonly actions?: React.ReactNode;
};

export function ViewBoxAppBar({
  primaryAction,
  title,
  actions,
}: ViewBoxAppBarProps) {
  const theme = useTheme();

  return (
    <>
      <ElevationScroll>
        <AppBar
          position="sticky"
          sx={{ paddingTop: "env(safe-area-inset-top)" }}
        >
          <Toolbar
            sx={{
              minHeight: "56px",
              [theme.breakpoints.up("md")]: {
                minHeight: "calc(64px)",
              },
            }}
          >
            <Container
              disableGutters
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: 1,
                [theme.breakpoints.up("lg")]: {
                  px: 3,
                },
              }}
            >
              {primaryAction && <Box>{primaryAction}</Box>}
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  overflow: "hidden",
                }}
              >
                {title && title}
              </Box>
              {actions && <Box sx={{ ml: "auto" }}>{actions}</Box>}
            </Container>
          </Toolbar>
        </AppBar>
      </ElevationScroll>
    </>
  );
}
