"use client";

import dynamic from "next/dynamic";
import { FormattedMessage } from "react-intl";
import Skeleton from "@mui/material/Skeleton";

import { ViewBoxAppBar } from "@/features/app-bar/ui/app-bar";
import { ViewBoxAppBarTitle } from "@/features/app-bar/ui/app-bar-title";

const MediaTypeTabs = dynamic(
  () =>
    import("./media-type-tabs").then(({ MediaTypeTabs }) => ({
      default: MediaTypeTabs,
    })),
  {
    loading: () => <Skeleton variant="rectangular" height={1104} />,
  },
);

const Featured = dynamic(
  () =>
    import("../../features/featured/ui/featured").then(({ Featured }) => ({
      default: Featured,
    })),
  {
    loading: () => <Skeleton variant="rectangular" height={222} />,
  },
);

export function MainPage() {
  return (
    <>
      <ViewBoxAppBar
        title={
          <ViewBoxAppBarTitle>
            <FormattedMessage id="main" />
          </ViewBoxAppBarTitle>
        }
      />

      <Featured />
      <MediaTypeTabs />
    </>
  );
}
