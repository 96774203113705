"use client";

import Head from "next/head";

import { MainPage } from "@/pages/main/main-page";

export default function Main() {
  return (
    <>
      <Head>
        <title>Главная | ViewBox</title>
        <link
          rel="apple-touch-icon"
          href="/apple-touch-icon.png"
          sizes="180x180"
        />
        <link rel="mask-icon" href="/viewbox-logo.svg" color="#FFFFFF" />

        <link rel="preconnect" href="https://apitmdb.cub.red" />
        <link
          rel="preconnect"
          href="https://imagetmdb.cub.red"
          crossOrigin=""
        />
      </Head>
      <MainPage />
    </>
  );
}
