import { cloneElement } from "react";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import type { AppBarProps } from "@mui/material/AppBar";

interface ElevationScrollProps {
  children: React.ReactElement;
}

export function ElevationScroll({ children }: ElevationScrollProps) {
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  });

  return cloneElement<AppBarProps>(children, {
    elevation: trigger ? 4 : 0,
  });
}
